html,
body,
#root
 {
  height: 100%;
}

.MuiToolbar-root .MuiBreadcrumbs-li a,
.MuiToolbar-root .MuiBreadcrumbs-separator
{
  color:#fff
}

.defaultTextStyle{
  font-family: 'Impact';
  font-size: '25px';
  fill: '#FFF';
  stroke: '#000';
}

.defaultBlackTextStyle{
  font-family: 'Arial';
  font-size: '25px';
  fill: '#FFF';  
}


@media (min-width: 768px) { 
/*
*/

 }